.bonego {
    margin-top: 160px;
}

.bonego-t {
    color: #009B90;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 15px;
}

.bonego-colort {
    background-color: #4DB9B1;
    color: #FFFFFF;
    font-size: 16px;
    padding: 2px 10px 2px 10px;
}

.bonego .div-img img {
    width: 220px;
}


.bonego-b2b {
    width: 160px;
    height: 40px;
    border: 2px solid #009B90;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 10px;
}

.bonego-b2b p {
    color: #009B90;
    font-size: 16px;
    font-weight: bold;
}
.bonego-b2b .mobi_font {
    font-size: 13px;
}

@media screen and (max-width: 768px) {
    .bonego-t {
        font-size: 30px;
        padding-top: 10px;
    }

    .bonego-colort {
        font-size: 14px;
    }
}

@media screen and (max-width: 1200px) {
    .service_en .bonego-colort {
        background: none;
    }

    .service_en .bonego-b2b {
        width: 100%;
    }

    .service_en .bonego-b2b {
        width: 50%;
    }

    .bonego-b2b p {
        font-size: 14px;
    }
    .bonego-b2b {
        width: 110px;
    }
}