@charset "UTF-8";

/* header CSS */
.header {
  width: 100%;
  height: 60px;
  background: #1b1b1eb8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  font-family: 'Noto Sans KR', sans-serif;
}

.header .header_wrap {
  margin: 0 auto;
  max-width: 1060px;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  gap: 60px;
  display: flex;
  justify-content: space-between;
}

.header .logo {
  width: 164px;
}

.header .gnb {
  display: flex;
  justify-self: center;
  gap: 40px;
}

.header .tnb {
  display: flex;
  gap: 25px;
  justify-self: end;

}

.header .gnb li:nth-child(5) {
  position: relative;
}

.header .gnb li:nth-child(5):after {
  content: 'N';
  position: absolute;
  width: 12px;
  height: 12px;
  right: -18px;
  font-size: 5px;
  border-radius: 3px;
  background: #C84A31;
  color: white;
  text-align: center;
  padding-right: 1px;
}

.header .tnb li a {
  color: white;
}

.header .tnb li {
  font-size: 12px;
  position: relative;
}

.header .lang_wrap .lang_list li {
  color: rgb(255 255 255 / 70%);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  transition: all 0.3s;
  cursor: pointer;
}

.header .lang_wrap .lang_list li.on {
  color: white;
  font-weight: bold;
}

.header .tnb li:first-child:after {
  content: '';
  width: 1px;
  height: 12px;
  background: #b5b5b5;
  position: absolute;
  right: -13px;
  top: 4px;
}

.tnb {
  color: white;
}

.cursor li {
  cursor: pointer;
}

.tnb .user_name p {
  color: #dddddd;
}

.tnb p span {
  color: white;
  font-weight: bold;
  margin-right: 3px;
}

.tnb button {
  cursor: pointer;
  color: white;
}

.header .header_right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header .lang_wrap {
  position: relative;
  height: 60px;
  width: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.header .lang_wrap .lang_img {
  background: none;
  text-indent: -9999px;
  background: url(../assets/images/lang_icon_white.svg) no-repeat;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background-position: 50%;
  opacity: 0.8;
  transition: all 0.3s;
}

.lang_list {
  position: absolute;
  top: 60px;
  background: #1b1b1eb8;
  display: grid;
  padding: 10px 25px 15px;
  gap: 15px;
  left: -28px;
  width: 85px;
  display: none;
  transition: all 0.3s;
}

.header .lang_wrap .lang_list li {
  text-indent: 0;
}

.header .lang_wrap .lang_list li a {
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  text-decoration: none;
  transition: all 0.3s;
  text-indent: 0;
}

.mo_gnb {
  height:100vh;
  width: auto;
  max-width: 400px;
  min-width: 240px;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #1b1b1ec4;
  z-index: 400;
  transform: translateX(100%);
  overflow: hidden;
  opacity: 0;
}

.mo_gnb ul {
  display: flex;
  flex-direction: column;
}

.header .mo_gnb .lang_wrap {

  margin-top: 50px;
  gap: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.mo_gnb li a {
  display: block;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  color: rgb(255 255 255 / 70%);
  text-decoration: none;
  padding-left: 16px;
}

.mo_gnb li a.on {
  color: white;
  font-weight: bold;
}

.header .mo_gnb .lang_wrap li {
  padding-left: 0;
  font-size: 12px;
}

.header .lang_wrap li.on {
  color: white;
}

.header .mo_gnb .lang_wrap li:first-child {
  position: relative;
}

.header .mo_gnb .lang_wrap li:first-child:before {
  content: '';
  position: absolute;
  right: -32px;
  background: #7f7f7f;
  width: 1px;
  height: 18px;
  top: 22px;
}

.menu_btn {
  width: 24px;
  height: 24px;
  position: relative;
  justify-self: right;
  align-self: center;
  border: none;
  display: none;
  cursor: pointer;
  background: url(../assets/images/hambuger_icon.svg) no-repeat;
  background-size: 18px;
  background-position: 50%;
  transition: all 0.1s;
}

.menu_btn.on {
  background: url(../assets/images/close.svg) no-repeat;
  background-size: 18px;
  background-position: 50%;
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, .6);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.sidebar-overlay.on {
  display: block;
  z-index: 999;
}

#footer {
  background-color: #333;
  color: #999;
  font-size: 14px;
}
#footerIn {
  max-width: 1060px;
  margin: 0 auto;
  padding: 30px 0;
}
#footer hr {
  border: 1px solid white;
  opacity: 0.5;
}
#footer  .copy {
  text-align: center;
   color: #c5c5c5;
  margin-top: 30px;
}
.finfo span {
  color: #c5c5c5;
  font-weight: 600;
}
@media screen and (max-width: 1060px) {
  .header .logo {
    width: 120px;
  }
  .header .header_wrap {
    padding: 0 16px;
  }

  .header .gnb {
    display: none;
  }

  .header .header_right {
    display: none;
  }

  .header .menu_btn {
    display: block;
  }

  .mo_gnb {
    opacity: 1;
    transition: all 0.5s;
    z-index: 1000;
  }

  .mo_gnb.on {
    transform: translateX(0);
    display: block;
  }

  .header .tnb {
    display: flex;
    height: 60px;
    align-items: center;
    background: #444;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }

  .header .tnb.cursor li:last-child {
    background: #747474;
    width: 65px;
    text-align: center;
    border-radius: 10px;
    padding: 8px 0;
  }

  .header .tnb li:first-child:after {
    display: none;
  }
  #footer {
    font-size: 12px;
    line-height: 1.6;
  }
  #footerIn  {
    padding: 30px 16px;
  }
}

/* header CSS END */