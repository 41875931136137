.cont2 {
    padding-bottom: 60px;
    background: #fafafa;
}

.under-div {
    max-width: 1060px;
    margin: 0 auto;
}


.under-grid {
    display: grid;
    grid-template-columns: 320px 1fr;
    gap: 30px
}

.unbox-p {
    font-size: 16px;
    color: #000100;
    text-align: left;
}

.contatct-border {
    margin-top: 20px;
    margin-bottom: 40px;
    border: 1px solid #000100;
    width: 180px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    font-size: 16px;
    color: #000100;
    transition: all 0.3s;
    background: white;
}


.contatct-border a {
    color: #000100;
    display: block;
    line-height: 40px;
    font-size: 16px;
    height: 40px;
}

@media screen and (max-width: 768px) {
    .cont2 {
        padding-bottom: 60px;
    }
    
    .cont2 .section_title {
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 16px;
    }

    .cont2 .under-grid>div>img {
        width: 90%;
        display: block;
        margin: auto;
    }

    .cont2 .section_title:after {
        bottom: 34px;
    }

    .section_title::after {
        right: -15px;
        bottom: 9px;
        width: 7px;
        height: 7px;
    }

    .unbox-p {
        font-size: 14px;
    }
    
    .under-box {
        margin-top: 20px;
        padding: 0 16px;
    }
    .under-grid {
        display: block;
    }

    .under-div {
        height: auto;
        margin: 0 auto;
    }
}

/* 
@media screen and (max-width: 1200px) {
    .cont2 {
        text-align: inherit;
        padding-top: 30px;
        padding-bottom: 60px;
    }
} */