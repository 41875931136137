@charset "utf-8";

#wrap {
    width: 100%;
    position: relative;
    min-width: 280px;
    overflow-x: hidden;
}

/* 메인 배너 */
#newsmainVisual {
    position: relative;
}

.newsmainSlider {
    width: 100%;
    height: auto;
}

.newsmainSlider .msImg {
    display: block;
    width: 100%;
    height: 200px;
    background-image: url('../assets/images/contact_t.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    transition: all 0.5s;
}

.newsmainSlider .tbox {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #eee;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 90%;
    color: #eee;
    margin-top: 30px;
}

.newsmainSlider h4 {
    font-size: 28px;
    line-height: 1em;
    margin: 0px 0 10px 0;
    color: #fff;
    font-weight: bold;
}

.newsmainSlider .tbox p {
    font-size: 18px;
}

@media screen and (min-width:768px) {

    .newsmainSlider h4 {
        font-size: 30px;
        line-height: 1em;
        margin: 0px 0 20px 0;
        color: #fff;
    }
}


@media screen and (min-width:1200px) {

    .newsmainSlider .msImg {
        background-image: url('../assets/images/contact.png');
        display: block;
        width: 100%;
        height: 330px;
    }

    .newsmainSlider h4 {
        font-size: 30px;
        margin: 0px 0 20px 0;
    }

    .newsmainSlider .tbox {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #eee;
        text-align: center;
        transform: translate(-50%, -50%);
        width: 100%;
        color: #eee;
        margin-top: 15px;
    }
}


/* 소식 내용 */
#newcontainer {
    padding: 0 16px;
    overflow: hidden;
}

.main_section {
    max-width: 1060px;
    margin: auto;
    padding:80px 0px;
}

#newcontainer img {
    display: block;
    width: 100%;
    object-fit: cover;
    height: 300px;
    transition: all 0.5s;
}

.storyFlexBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    margin-bottom: 60px;
}

.main_section a {
    color: #000;
}
.story li {
    overflow: hidden;
}
.story li:nth-child(2) {
    border: 1px solid #dddddd;
    border-top: none;
    padding: 20px;
    line-height: 30px;
}

.story_title {
    font-size: 18px;
    font-weight: 600;
}

.day {
    font-size: 16px;
    font-weight: 600;
    color: #858585;
}
.story {
    box-shadow: 0 0 10px -3px #d0d0d0;
    transition: all 0.3s;
}

.arrow {
    width: 50px;
    margin: auto;
    text-align: center;
    cursor: pointer;
}
.blind_view{
    display: block;
}
.blind_view.on{
    display: none;
}

.arrow_title {
    font-size: 14px;
    font-weight: 600;
    color: #AA1744;
    margin-top: 10px;
}
.story02 {
    display: none;
}
.story02.on {
    display: block;
}
#newcontainer .blind_view .more_btn {
    height: 35px;
    width: 35px;
    margin: 0 auto;
    transition: all 0.3s;
}
@media (min-width: 768px) and (max-width: 1000px) {
    .main_section {
        max-width: 1060px;
        margin: auto;
        padding-top: 120px;
    }
    .storyFlexBox {
        gap: 30px;
    margin-bottom: 30px;
    }

    .story {
        width: auto;
        /* margin: 0 40px 80px; */
        float: left;
    }

    .story_title {
        font-size: 15px;
        letter-spacing: -0.5px;
        font-weight: 600;
    }
}

@media (max-width: 768px) {
    .main_section {
        max-width: 1060px;
        overflow: hidden;
        margin: auto;
        padding: 60px 0px;
    }

    #container img {
        height: auto;
    }

    .storyFlexBox {
        grid-template-columns: 1fr;
        gap: 40px;
        margin-bottom: 40px;
    }

    .story {
        width:auto;
        /* margin: 0 40px 80px; */
        float: none;
    }

    .plus {
        margin-right: 0px;
    }

    .story_title {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.3;
    }
    .story li {
        height: auto;
    }
    .newsmainSlider h4{
        font-size: 22px;
      }
      #container .blind_view .more_btn {
        width: 25px;
        height: 25px;
        margin: 0 auto;
      }
      .arrow_title {
        font-size: 14px;
        margin: 5px;
      }
}