#members {
    margin-top: 220px;
  }

.members_wrap {
    display: flex;
    flex: 1 1 1;
    gap: 20px;
    justify-content: space-between;
}

.member-card {
    gap: 7px;
    width: 300px; /* 가로 크기 고정 */
    height: 300px; /* 세로 크기 고정 */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* 요소 간의 공간을 동일하게 분배 */
    align-items: center; /* 수평 중앙 정렬 */
    border: 1px solid #ccc; /* 카드 테두리 (선택 사항) */
    padding: 10px; /* 카드 내부 여백 */
    text-align: center; /* 텍스트 중앙 정렬 */
    transition: transform 0.2s; /* 호버 효과를 위한 전환 */
}

.member-card img {
    max-width: 100%; /* 이미지의 최대 너비를 카드의 너비에 맞춤 */
    max-height: 200px; /* 이미지의 최대 높이 고정 */
    object-fit: contain; /* 이미지 비율 유지하면서 공간에 맞춤 */
    margin-bottom: 10px; /* 이미지와 텍스트 간격 */
}

.member-card h3, .member-card p {
    text-align: center; /* 텍스트 중앙 정렬 */
}

.member-card h3 {
    font-size: 1.5em; /* 이름 크기 */
    margin: 0; /* 기본 여백 제거 */
}

.member-card p {
    font-size: 1em; /* 설명 크기 */
    color: #666; /* 설명 색상 */
}

@media (max-width: 768px) {
    .members_wrap {
      flex-direction: column;
      align-items: center;
    }
  
    .member-card {
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
    }
  }