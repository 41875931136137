@charset "utf-8";

@font-face {
  font-family: "SBAggroB";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2108@1.1/SBAggroB.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500&display=swap");

#container {
  font-family: "Noto Sans KR", sans-serif !important;
}

.desk_none {
  display: none;
}
html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #fff;
}

#wrap {
  width: 100%;
  position: relative;
  min-width: 320px;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  #wrap {
    width: 100%;
    position: relative;
    min-width: 280px;
    overflow-x: hidden;
  }
}

.last_text {
  font-size: 24px;
}
.tbox-p {
  font-size: 16px;
  line-height: 1.4em;
  color: #000000;
  position: absolute;
  bottom: 45px;
  left: 50%;
  margin-left: -480px;
  max-width: 960px;
  bottom: 15px;
  /* margin: 0 auto; */
  display: inline-block;
}

/*모바일 사이드메뉴*/
.blackBg {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.45;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 200;
}

.sideMenu {
  width: 270px;
  height: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  right: -270px;
  z-index: 300;
}

.sideMenu .sideTop {
  width: 100%;
  height: 60px;
  padding-top: 8px;
}

.sideMenu .sideTop .closeBtn {
  background: none;
  border: none;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.sideMenu .sideTop .closeBtn img {
  width: 20px;
  height: auto;
}

.sideMenu .mGnb {
  margin-left: 10px;
}

.sideMenu .mGnb li .m {
  display: block;
  font-weight: bold;
  font-size: 16px;
  padding: 20px 16px;
}

.sideMenu .mGnb li:hover .m {
  color: #aa1744;
}

.sideMenu .mSelect_lang {
  margin-top: 80px;
  margin-left: 10px;
}

.mSelect_lang p {
  padding: 20px 16px 12px;
  font-size: 16px;
  font-weight: bold;
}

.mSelect_lang li {
  float: left;
  padding-left: 16px;
}

.mSelect_lang > ul > li > a {
  display: block;
  color: #aa1744;
}

/*메인 배너 (슬라이드)*/
#mainVisual {
  padding-top: 0px;
}

.mainSlider {
  width: 100%;
  height: auto;
}

.mainSlider .msImg {
  display: block;
  height: 100vh;
  width: 100vw;
  background-image: url("../assets/images/servicebanner.svg");
  background-repeat: no-repeat;
  background-position: 40% 30%;
  background-size: cover;
  overflow: hidden;
  position: relative;
  transition: all 0.5s;
  object-fit: cover;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: white;
  color: black;
  font-size: 20px;
  border-radius: 8px;
}
.mainSlider .tbox {
  position: absolute;
  color: #eee;
  font-size: 16px;
  max-width: 960px;
  margin: 0px auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 8px 8px 10px 0 #00000033;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  text-align: center;
  padding: 20px;
}
.mainSlider .tbox .ms_text_wrap {
  padding: 50px;
  border: 1px solid rgba(255, 255, 255, 0.7);
}
#mb_slide {
  display: none;
}
.go_dantaro_btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 15%;
  border: 1px solid #ffffff;
  width: 180px;
  background: none;
  line-height: 50px;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}
.go_dantaro_btn:hover {
  background-color: #000100;
  border: 1px solid #000100;
}
.mainSlider.en h4 {
  font-size: 30px;
}
.ms_down_icon {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0);
  background: none;
  cursor: pointer;
  border: none;
  border-radius: 100%;
  transition: all 0.3s;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ms_down_icon:hover {
  background: #ffffff40;
}
.ms_down_icon a {
  height: 45px;
}
.ms_down_icon img {
  width: 45px;
}
.mainSlider h4 {
  font-size: 37px;
  line-height: 1.4;
  margin: 30px 0;
  font-weight: 400;
  font-family: "SBAggroB";
}

.mainSlider.en h4 {
  font-size: 30px;
}


.mainSlider img.mobi_none {
  width: 260px;
}

.ms_text_wrap .sub_title {
  font-family: "Noto Sans KR";
  font-size: 18px;
  opacity: 0.9;
  line-height: 1.7;
}

.ms_text_wrap .sub_title.en {
  font-size: 16px;
}

.mainSlider .boldspan {
  font-weight: bold;
}

.point_text {
  font-weight: bold;
  color: #aa1744;
}

/* 지엠씨랩스 회사소개 내용 시작 */
#container {
  max-width: 1060px;
  margin: 0 auto;
}

#aboutUs .text_wrap {
  margin-top: 200px;
}
.section_title {
  font-size: 45px;
  margin-bottom: 20px;
  line-height: normal;
  display: inline-block;
  font-weight: 600;
  position: relative;
}
.section_title:after {
  content: "";
  position: absolute;
  right: -18px;
  bottom: 11px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #aa1744;
}

.body_text {
  font-size: 20px;
  line-height: 1.6;
}
.last_text {
  margin-top: 10px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}
#aboutUs {
  display: grid;
}
#aboutUs .img_wrap {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  height: 360px;
}
#aboutUs .img_wrap > div {
  position: relative;
  transition: all 0.3s;
}
#aboutUs .img_wrap .left_img {
  background: url("../assets/images/aboutUs_img1.jpg") no-repeat;
  background-size: cover;
  box-shadow: 0 0 10px -3px #a9a9a9;
}
#aboutUs .img_wrap .right_img {
  background: url("../assets/images/aboutUs_img2.jpg") no-repeat;
  background-size: cover;
  box-shadow: 0 0 10px -3px #a9a9a9;
}
#aboutUs .img_wrap > div > p {
  font-size: 20px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: white;
}
.more_bnt {
  font-family: inherit;
  border: 1px solid #000100;
  width: 150px;
  background: none;
  line-height: 50px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
  float: right;
  margin-top: 40px;
  justify-self: end;
  position: relative;
  overflow: hidden;
  color: #333;
}
.more_bnt:before {
  content: "";
  background: #a91744;
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  transition: all 0.3s;
  left: -100%;
  z-index: -1;
}
#MissionVision {
  margin-top: 180px;
}
#MissionVision .MissionVision_img {
  margin-bottom: 80px;
  height: 398px;
  background: url('../assets/images/section2_img.jpg') no-repeat;
}

#MissionVision .text_wrap {
  display: grid;
  gap: 15px;
}

#history {
  margin-top: 220px;
}
#history .history_img {
  height: 398px;
  background: url('../assets/images/section3_img.jpg') no-repeat;
}
#history .grid_box {
  display: flex;
  gap: 80px;
}

#history .year {
  content: "";
  font-size: 32px;
  color: #a91744;
  font-weight: 600;
  padding-right: 50px;
  position: relative;
  transition: all 0.3s;
  width: 125px;
}

/* #history .year:before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #000010;
  position: absolute;
  right: -12px;
  top: 20px;
} */

#history .section_title {
  margin-top: 80px;
  margin-bottom: 60px;
}

#history .history_contents {
  font-size: 20px;
  display: grid;
  gap: 20px;
}
#history .month_wrap > p {
  font-weight: bold;

}
#history .month_wrap {
  display: flex;
  gap: 30px;
}
#history .history_text {
  display: grid;
  gap: 5px;
}

#history .history_text p{
  height: 30px;
}

#history .history_wrap {
  display: grid;
  gap: 70px;
  position: relative;
}
#history .history_wrap:before {
  content: "";
  width: 2px;
  height: calc(100% + 40px);
  background: #ebebeb;
  position: absolute;
  left: 130px;
  margin-top: -20px;
}

/* 협력사 */

.cont4 {
  margin-top: 200px;
  margin-bottom: 400px;
}
.cont4 .ptbox ul {
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 13px;
  margin-bottom: 13px;
}

.cont4 .ptbox li {
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  text-indent: -9999px;
  background-position: center;
  display: inline-block;
  background-size: contain;
  border: 1px solid #e2e2e2;
  box-shadow: 0 0 5px 0px #ededed;
  transition: all 0.5s;
}

.cont4 .ptbox .cf-img01 li:nth-child(1) {
  background-image: url('../assets/images/partners_1.svg');
}

.cont4 .ptbox .cf-img01 li:nth-child(2) {
  background-image: url('../assets/images/partners_2.svg');
}

.cont4 .ptbox .cf-img01 li:nth-child(3) {
  background-image: url('../assets/images/partners_3.svg');
}
.cont4 .ptbox .cf-img01 li:nth-child(4) {
  background-image: url('../assets/images/partners_4.svg');
}

.cont4 .ptbox .cf-img02 li:nth-child(1) {
  background-image: url('../assets/images/partners_5.svg');
}

.cont4 .ptbox .cf-img02 li:nth-child(2) {
  background-image: url('../assets/images/partners_6.svg');
}

.cont4 .ptbox .cf-img02 li:nth-child(3) {
  background-image: url('../assets/images/partners_7.svg');
}
.cont4 .ptbox .cf-img02 li:nth-child(4) {
  background-image: url('../assets/images/partners_8.svg');
}

.cont4 .ptbox .cf-img03 li:nth-child(1) {
  background-image: url('../assets/images/partners_9.svg');
}

.cont4 .ptbox .cf-img03 li:nth-child(2) {
  background-image: url('../assets/images/partners_10.svg');
}

.cont4 .ptbox .cf-img03 li:nth-child(3) {
  background-image: url('../assets/images/partners_11.svg');
}
.cont4 .ptbox .cf-img03 li:nth-child(4) {
  background-image: url('../assets/images/partners_12.svg');
}

.cont4 .ptbox .cf-img04 li:nth-child(1) {
  background-image: url('../assets/images/partners_13.svg');
}

.cont4 .ptbox .cf-img04 li:nth-child(2) {
  background-image: url('../assets/images/partners_14.svg');
}

.cont4 .ptbox .cf-img04 li:nth-child(3) {
  background-image: url('../assets/images/partners_15.svg');
}
.cont4 .ptbox .cf-img04 li:nth-child(4) {
  background-image: url('../assets/images/partners_16.svg');
}

.affiliate_section {
  margin-top: 220px;
}
.aff_wrap {
  display: grid;
  grid-template-columns: 290px 1fr;
  gap: 50px;
  align-items: center;
}
.left_logo {
  background: url('../assets/images/Dfians_logo.svg') no-repeat;
  height: 178px;
  border: 1px solid #d0cece;
  background-position: 50%;
  background-size: 200px;
}
.aff_title {
  font-size: 26px;
  font-weight: bold;
}
.aff_subtitle {
  font-size: 18px;
  color: #878787;
  margin-top: 5px;
  font-weight: 300;
}
.aff_text {
  font-size: 20px;
  margin-top: 20px;
  line-height: 1.4;
}
.affiliate_section .more_bnt {
  width: 200px;
}

.affiliate_img {
  height: 398px;
  background: url('../assets/images/affiliate.jpg') no-repeat;
  margin-bottom: 80px;
}

@media screen and (max-width: 1280px) {
  .mainSlider h4 {
    font-size: 28px;
  }
  .mainSlider.en h4 {
    font-size: 22px;
  }
}

@media screen and (max-width: 1000px) {
  .mainSlider .tbox {
    max-width: 100%;
    width: calc(100% - 40px);
    padding: 10px;
  }
  .mainSlider .tbox .ms_text_wrap {
    padding: 40px 0px;
    width: 100%;
  }
  .mainSlider .tbox p {
    line-height: 1.5;
    font-size: 14px;
  }
  .mainSlider .ms_text_wrap img {
    width: 150px;
    margin: 0 auto;
  }
  .mainSlider h4 {
    font-size: 22px;
    max-width: 290px;
    min-width: 170px;
    width: 100%;
    margin: 0 auto;
    padding: 24px 0;
  }
  .mainSlider.en h4 {
    font-size: 18px;
    padding: 10px 0;
  }
  #MissionVision {
    margin-top: 90px;
  }
  #MissionVision .MissionVision_img {
    margin-bottom: 40px;
    background-position: center;
    background-size: cover;
    height: 200px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }

  #container {
    max-width: none;
    padding: 0 16px;
  }
  #history {
    margin-top: 110px;
  }
  #history .section_title {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  #history .grid_box {
    display: grid;
    gap: 10px;
  }
  #history .year {
    font-size: 22px;
  }
  #history .month_wrap {
    gap: 15px;
  }
  #history .history_text {
    font-size: 14px;
  }
  #history .year:before {
    display: none;
  }
  #history .history_wrap:before {
    display: none;
  }
  #aboutUs .img_wrap {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 30px;
  }
  .body_text {
    font-size: 14px;
  }
  .last_text {
    font-size: 14px;
  }
  #section_top {
    height: 60px;
  }
  #aboutUs .text_wrap {
    margin-top: 60px;
  }
  .more_bnt {
    font-size: 14px;
    width: 120px;
    line-height: 40px;
    margin-top: 20px;
  }
  .section_title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .cont4 {
    margin-top: 170px;
    margin-bottom: 200px;
  }
  #MissionVision .MissionVision_img {
    margin-bottom: 40px;
  }
  #history .history_img {
    background: url('../assets/images/section3_img_m.jpg');
    background-position: center;
    background-size: cover;
    height: 200px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  .cont4 .ptbox ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
  }
  #aboutUs .img_wrap .left_img {
    background: url('../assets/images/aboutUs_img1_m.jpg') no-repeat;
    background-size: cover;
  }
  #aboutUs .img_wrap .right_img {
    background: url('../assets/images/aboutUs_img2_m.jpg') no-repeat;
    background-size: cover;
  }
  #MissionVision .MissionVision_img {
    background: url('../assets/images/section2_img_m.jpg') no-repeat;
    background-size: cover;
    background-position: center;
  }
  .aff_title {
    font-size: 22px;
  }
  .aff_subtitle {
    font-size: 14px;
  }
  .aff_text {
    font-size: 14px;
  }
  .aff_wrap {
    grid-template-columns: 1fr;
    gap: 25px;
  }
  .left_logo {
    width: 100%;
    background-size: 160px;
    height: 140px;
  }
  .mobi_none {
    display: none;
  }
  .desk_none {
    display: block;
  }
  #history .month_wrap > p {
    font-size: 18px;
  }
  #history .history_wrap {
    gap: 35px;
  }
  #history .history_contents {
    gap: 8px;
  }
  .section_title:after {
    right: -15px;
    bottom: 9px;
    width: 7px;
    height: 7px;
  }
  .affiliate_img {
    background-size: cover;
    height: 200px;
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  #mb_slide {
    display: block;
  }
  #desk_slide {
    display: none;
  }
  .mainSlider .msImg {
    object-fit: cover;
  }
  .ms_down_icon {
    width: 50px;
    height: 50px;
    background: #ffffff40;
  }
  .ms_down_icon a {
    width: 35px;
    height: 35px;
    margin-top: 3px;
  }
  .ms_down_icon img {
    width: 35px;
    height: 35px;
  }
}
