/* 모으고 */
.moeugo {
    text-align: center;
}

.mougo {
    padding-top: 160px;
}

.mougo-t {
    color: #2A57D8;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 15px;
}

.mougo .div-img img {
    width: 220px;
}

.mougo-colort {
    background-color: #6A89E4;
    color: #FFFFFF;
    font-size: 16px;
    padding: 2px 10px 2px 10px;
}

.mougo-b2b {
    width: 160px;
    height: 40px;
    border: 2px solid #2A57D8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 10px;
}

.mougo-b2b p {
    color: #2A57D8;
    font-size: 16px;
    font-weight: bold;
}

.flexcontainer .in2 {
    border: 2px solid #0F1F49;
    width: 260px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 39px 0;
    box-shadow: 0px 0px 3px rgb(0, 0, 0, 0.5);
    transition: all 0.5s;
}

.flexcontainer .in2 h5 {
    font-size: 20px;
    margin-bottom: 20px;
}

.flexcontainer .in2 p {
    line-height: 24px;
}

.flexcontainer .in2 strong {
    font-size: 26px;
    color: #0F1F49;
    font-family: 'Titillium Web', sans-serif;
    line-height: 22px;
    margin-bottom: 10px;
    display: block;
}

ul.phone_moeugo {
    max-width: 1060px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

ul.phone_moeugo li {
    /* width: 100% */
}

ul.phone_moeugo img {
    display: inline-block;
    height: auto;
    max-width: 300px;
}

@media screen and (max-width: 900px) {
    ul.phone_moeugo img {
        max-width: 240px;
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .moeugo .logo {
        height: 220px;
        background-size: 300px auto;
    }

    .moeugo h4 {
        font-size: 30px;
    }

    .phone {
        width: 100%;
        overflow: hidden;
    }

    ul.phone_moeugo {
        max-width: 1060px;
        margin: auto;
        text-align: center;
    }

    .flexcontainer .in2 {
        width: 260px;
    }

    ul.phone_moeugo {
        flex-direction: column;
    }

    ul.phone_moeugo img {
        max-width: 360px;
        height: auto;
    }
}

@media screen and (max-width: 400px) {
    .moeugo .logo {
        height: 220px;
        background-size: 200px auto;
    }
}