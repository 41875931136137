@charset "UTF-8";
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
html {
    scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  }
.finfo > p {
    height: 21px;
    font-size: 14px;
}
.container {
    font-family: 'Pretendard', 'Noto Sans KR', sans-serif;
}
  a{
    text-decoration: none;
  }
::selection {
    background: #ded6ce;
    color: white;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #3d3936;
  }
  ::-webkit-scrollbar-track  {
    background: #fafafa;
  }