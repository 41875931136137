.buligo {
    margin-top: 160px;
    margin-bottom: 100px;
}

.modal_wrap_fab .modal_inner_buligo {
    min-height: 1276px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    padding: 40px 16px;
    max-width: 400px;
    min-width: 310px;
    width: calc(100% - 32px);
}

.div-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.buligo-t {
    color: #562AAA;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 15px;
}

.buligo-colort {
    background-color: #8475B1;
    color: #FFFFFF;
    font-size: 16px;
    padding: 2px 10px 2px 10px;
}

.buligo .div-img img {
    width: 220px;
}

.service-end {
    width: 160px;
    height: 40px;
    border: 2px solid #562AAA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 10px;
}

.service-end p {
    color: #562AAA;
    font-size: 16px;
    font-weight: bold;
}

.service-end .mobi_font {
    font-size: 13px;
}

@media screen and (max-width: 768px) {
    .buligo-colort {
        font-size: 14px;
    }
    .service-end {
        width: 110px;
    }
}