.gray_box {
    background: #fafafa;
    padding: 20px;
    text-align: left;
}


.sirius .leoalpha_div {
    display: flex;
    text-align: left;
    align-items: center;
}

.sirius .leoalpha_font {
    color: #000100;
    font-size: 28px;
    text-align: left;
    font-weight: bold;
}

.leoalpha_boxfont {
    margin-left: 20px;
    color: #FFFFFF;
    background-color: #AA1744;
    font-size: 14px;
    width: 100px;
    height: 25px;
    line-height: 22px;
    border-radius: 20px;
    text-align: center;
}

.sirius .big-p {
    font-size: 18px;
    color: #000100;
    font-weight: 400;
    text-align: left;
    word-break: keep-all;
    line-height: 25px;
    margin-top: 15px;
}

.sirius .leoalpha_div2 {
    display: grid;
    line-height: 1.4;
    margin-top: 30px;
    gap: 10px;
}

.sirius .leoalpha_div2 img {
    padding-right: 10px;
}

.sirius .leoalpha_div2 p {
    font-size: 18px;
    text-align: left;
    color: #000100;
}

.sirius .leoalpha_flex {
    display: flex;
}


.flexcontainer .title-box {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
    color: #000100;
}

.flexcontainer .inbox-line {
    line-height: 25px;
}

.flexcontainer .inbox-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexcontainer .inbox-p {
    font-size: 14px;
    color: #000100;
    text-align: left;
    padding: 5px 0;
}

.flexcontainer .inbox-num {
    font-size: 14px;
    color: #000100;
    font-weight: bold;
}

.flexcontainer .title-sub {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 15px;
    color: #000100;
}

.flexcontainer_underp {
    text-align: left;
    font-size: 14px;
    color: #797979;
    margin-top: 15px;
    word-break: keep-all;
}

.sirius .service-box2 {
    margin-top: 80px;
    background: #fafafa;
    padding: 20px;
}

.flexcontainer_underp2 {
    text-align: left;
    font-size: 14px;
    color: #797979;
    margin-top: 20px;
    margin-left: 5px;
    margin-bottom: 40px;
    word-break: keep-all;
}

.flexcontainer2 {
    max-width: 1060px;
    padding-top: 30px;
    margin-bottom: 30px;
    text-align: -webkit-center;
}
.flexcontainer2 .title-box {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 20px;
    color: #000100;
}
.flexcontainer2 .inbox-num {
    font-size: 14px;
    color: #000100;
    font-weight: bold;
}
.gridcontainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
}
.flexcontainer2 .in {
    border: 1px solid #D8DEE5;
    padding: 15px 10px 20px 10px;
    transition: all 0.5s;
}

.flexcontainer2 .inbox-line {
    line-height: 25px;
}

.flexcontainer2 .inbox-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexcontainer2 .inbox-p {
    font-size: 14px;
    color: #000100;
    text-align: left;
    padding: 5px 0;
    line-height: 18px;
}

.flexcontainer2.inbox-num {
    font-size: 14px;
    color: #000100;
    font-weight: bold;
}

.flexcontainer2 .title-sub {
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 15px;
    color: #000100;
}

.sirius .line {
    display: block;
    width: 1px;
    height: 60px;
    background: #ddd;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    opacity: 0;
}

.sirius .ltxt {
    line-height: 24px;
}

.flexcontainer {
    max-width: 1060px;
    display: flex;
    padding-top: 30px;
    margin-bottom: 30px;
    margin: 0px auto;
    text-align: -webkit-center;
    gap: 15px;
}

.flexcontainer .box1 {
    width: 100%;
}

.flexcontainer .box2 {
    width: 100%;
}

.flexcontainer .box3 {
    width: 100%;
}

.flexcontainer .box4 {
    width: 100%;
}

.flexcontainer .in {
    border: 1px solid #D8DEE5;
    padding: 15px 10px 20px 10px;
    transition: all 0.5s;
}

/* 영문 버젼 */

.service_en .leoalpha_boxfont {
    width: auto;
    padding: 0 15px;
}

@media screen and (max-width: 768px) {

    .sirius .flexcontainer {
        height: 100%;
        flex-direction: column;
    }

    .sirius .gridcontainer {
        grid-template-columns: 1fr;
    }

    .sirius .flexcontainer2 {
        height: 100%;
        flex-direction: column;
    }

    .sirius .big-p {
        font-size: 16px;
        margin-top: 12px;
        line-height: 1.4;
    }
    .sirius .leoalpha_div2 {
        margin-top: 15px;
    }
    .sirius .leoalpha_div2 p {
        font-size: 16px;
    }
    .sirius .leoalpha_div2 .padding-5 {
        padding-bottom: 10px;
    }
    .sirius .leoalpha_div2 p {
        font-size: 14px;
    }

    .sirius .leoalpha_font {
        font-size: 20px;
    }

    .flexcontainer .box1, .flexcontainer .box2, .flexcontainer .box3, .flexcontainer .box4 {
        width: 100%;
    }

    .flexcontainer .in[data-v-4320c7e8] {
        width: auto;
    }
}