.chart {
    max-width: 1060px;
    margin-bottom: 100px;
}
.chart .trading_flex {
    display: flex;
}

.chart .display_grid {
    margin-bottom: 80px;
    display: grid;
    gap:60px;
    grid-template-columns: 1fr 1fr;
}
.chart .display_grid > div {
    background: #fafafa;
    padding: 20px;
}

.chart .leoalpha_font {
    color: #000100;
    font-size: 28px;
    text-align: left;
    line-height: 25px;
    font-weight: bold;
}


.chart .big-p {
    font-size: 18px;
    color: #000100;
    font-weight: 400;
    text-align: left;
    word-break: keep-all;
    line-height: 25px;
    margin-top: 15px;
}

.chart .trading-line {
    line-height: 1.4;
    padding-top: 20px;
    display: grid;
    gap: 10px;
}

.chart .trading-line p {
    font-size: 18px;
    text-align: left;
    color: #000100;
}

.chart .trading-line img {
    padding-right: 10px;
}

@media (max-width: 768px) {
    .chart .display_grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .chart .big-p {
        font-size: 16px;
    }

    .chart .trading-line p {
        font-size: 16px;
    }
    .chart .leoalpha_font {
        font-size: 24px;
    }

    .chart .big-p {
        font-size: 16px;
        margin-top: 16px;
        line-height: 1.4;
    }

    .chart .trading-line p {
        font-size: 16px;
    }
}