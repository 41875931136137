/* 메인 배너 */
#servicemainVisual {
    position: relative;
}

#container .grid-service {
    margin-top: 80px;
}


#container .grid-display {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}

#container .grid-display .flex-div img {
    width: 20px;
    height: 20px;
}

#container .grid-display .flex-div p {
    color: #000100;
    font-size: 18px;
    text-align: left;
}


.servicemainSlider {
    width: 100%;
    height: auto;
}

.servicemainSlider .msImg {
    display: block;
    width: 100%;
    height: 200px;
    background-image: url("../assets/images/servicebanner.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    transition: all 0.5s;
}

.servicemainSlider .tbox {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #eee;
    text-align: left;
    transform: translate(-50%, -50%);
    width: 90%;
    color: #eee;
}

.servicemainSlider h4 {
    font-size: 30px;
    font-weight: bold;
    line-height: 1em;
    color: white;
    text-align: center;
}

.servicemainSlider .tbox p {
    font-size: 18px;
}


/*section*/
#container > section {
    /* padding: 0 10px; */
}

#container > section.sirius {
    padding-top: 0px;
    max-width: 1060px;
    margin: 0px auto;
}

#container > section.phone {
    /* padding-bottom: 50px; */
    max-width: 1060px;
    margin: 0px auto;
    margin-bottom: 80px;
}

#container > section:nth-child(1) {
    padding: 0;
    margin: 0 auto;
    max-width: 1060px;
}


.moeugo .btndiv {
    width: 100%;
    display: flex;
    margin-top: 80px;
    box-shadow: 0 0 10px -5px #ccc;
    border: 1px solid #f3f3f3;
}

.moeugo .btndiv button {
    width: 100%;
    height: 70px;
    font-size: 18px;
    padding:0;
    border: none;
    background: #fafafa;
    transition: all 0.1s;
}

.moeugo .btndiv .btn1 {
    color: #A91744;
    background: white;;
    font-weight: bold;
    border-top: 4px solid #aa1744;
    box-shadow: 5px 0px 10px -5px #ccc;
    z-index: 2;
}
.moeugo .btndiv button:hover {
    box-shadow: 5px 0px 10px -5px #ccc;
    border-top: 4px solid #aa1744;
    background: white;
}
.moeugo .btndiv .btn1 a:hover {
    color: #aa1744;
}
.moeugo .btndiv .btn1 a {
    color: #A91744;
    display: block;
    font-weight: bold;
}

.moeugo .btndiv .btn2 {
z-index: 1;
border-right: 1px solid #efefef;
}
.moeugo .btndiv .btn3 {
    z-index: 1;
}

.moeugo .btndiv .btn3:hover  {
    box-shadow: -5px 0px 10px -5px #ccc;
}

.moeugo .btndiv button a {
    color: #ccc;
    display: block;
    word-break: keep-all;
    line-height: 70px;
}


.moeugo .btndiv button a:hover {
    color: #999;
}

.moeugo .mobile-img {
    width: 100%;
}

.moeugo {
    text-align: center;
}

.moeugo h4 {
    font-size: 30px;
    line-height: 40px;
    color: #0F1F49;
    margin-bottom: 30px;
    margin-top: 40px;
}

.moeugo .btopen {
    padding: 20px;
    display: block;
    font-size: 16px;
}

.moeugo .btopbtn {
    color: #0F1F49;
    font-weight: 600;
    /* line-height: 1.2em; */
    width: 186px;
    padding: 10px;
    border: 2px solid #0F1F49;
    background-color: #fff;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.5s;
    font-weight: bold;
    /* margin-bottom: 30px; */
    font-size: 16px;
}

.moeugo .btopbtn a {
    color: #242424;
    font-weight: 600;
    /* line-height: 1.2em; */
    width: 186px;
    padding: 10px;
    border: 2px solid #242424;
    background-color: #fff;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.5s;
    font-weight: bold;
    /* margin-bottom: 30px; */
    font-size: 16px;
}

.moeugo .btopbtn a:hover {
    color: #fff;
    background-color: #000A66;
}


.moeugo .line {
    display: block;
    width: 1px;
    height: 60px;
    background: #ddd;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
}

.moeugo .ltxt {
    line-height: 24px;
}

ul.phone_moeugo img {
    display: inline-block;
    height: auto;
    max-width: 300px;
}

.section_title_box {
    margin-top: 60px;
    margin-bottom: 25px;
    height: 70px;
    display: grid;
    justify-content: start;
    align-items: center;
}
.section_title {
    font-size: 45px;
    font-weight: bold ;
    color: #333;
    position: relative;
    display: inline-block;
    margin-bottom: 30px;
}
.section_title::after {
    content: '';
    position: absolute;
    right: -18px;
    bottom: 11px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #AA1744;
}


.dantaro-t {
    color: #0A174E;
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 15px;
}

.dantaro-colort {
    background-color: #545D83;
    color: #FFFFFF;
    font-size: 16px;
    padding: 2px 10px;
}


.dantaro_box {
    display: flex;
    gap: 20px;
    justify-content: center;
}
.dantaro-b2b {
    width: 160px;
    height: 40px;
    border: 2px solid #0A174E;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    border-radius: 10px;
}

.dantaro-b2b p {
    color: #0A174E;
    font-size: 16px;
    font-weight: bold;
}

.dantaro-b2b.dantaro_go {
    border: 2px solid #0A174E;
    background: #0A174E;
    cursor: pointer;
    transition: all 0.3s;
}
.dantaro-b2b.dantaro_go:hover {
    background: #3083DC;
    border: 2px solid #3083DC;
}
.dantaro-b2b.dantaro_chart{
    background: #3083DC;
    cursor: pointer;
    border: 2px solid #3083DC;
    
}
.dantaro-b2b.dantaro_go p {
    color: white;
}
.dantaro-b2b.dantaro_chart p {
    color: white;
}

.Dantaro .grid-under .flex-underbox {
    background-color: #0A174E;
    border: 1px solid #0A174E;
}

.div-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Dantaro .div-img img {
    width: 220px;
}


.flex-divline {
    line-height: 1.4;
    gap: 20px;
}

.grid-display .flex-div img {
    width: 20px;
    height: 20px;
}

.grid-display .flex-div p {
    color: #000100;
    font-size: 18px;
    text-align: left;
}

.flex-div {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}


.grid-under {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    margin-top: 60px;
}

.Dantaro .grid-under .flex-underbox {
    background-color: #0A174E;
    border: 1px solid #0A174E;
}

.Dfians .grid-under .flex-underbox {
    background-color: #2B2826;
    border: 1px solid #2B2826;
}

.bonego .grid-under .flex-underbox {
    background-color: #009B90;
    border: 1px solid #009B90;
}

.mougo .grid-under .flex-underbox {
    background-color: #2A57D8;
    border: 1px solid #2A57D8;
}


.flex-underbox {
    background-color: #4F3A8F;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4F3A8F;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 5px 2px 5px;
    word-break: keep-all;
}

.desk_none {
    display: inline-block;
}

.cont2_en .contatct-border {
    width: auto;
    display: inline-block;
    padding: 0 20px;
}

.service_en .dantaro-colort[data-v-894620ec], .service_en .mougo-colort[data-v-894620ec], .service_en .difians-colort[data-v-894620ec], .service_en .bonego-colort[data-v-894620ec], .service_en .buligo-colort[data-v-894620ec] {
    background: none;
}


@media screen and (max-width: 1200px) {
    .service_en .dantaro-colort,
.service_en .mougo-colort,
.service_en .difians-colort,
.service_en .bonego-colort,
.service_en .buligo-colort {
    background: none;
}
.sub_title_wrap {
    padding: 10px 5px;
    line-height: 1.2;
}
.service_en .dantaro .sub_title_wrap {
  background: #0A174E;
}

.service_en .Dfians .sub_title_wrap {
    background: #2B2826;
}

.service_en .bonego .sub_title_wrap {
    background: #009B90;
}

.service_en .mougo .sub_title_wrap {
    background: #2A57D8;
}
.service_en .buligo .sub_title_wrap {
    background: #562AAA;
}
.dantaro-b2b {
    margin-top: 0;
    width: 100%;
    max-width: 180px;
}
.dantaro_box{
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-top: 30px;
}
.service_en .dantaro_box {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin-top: 30px;
} 
.service_en .service-end, 
.service_en .bonego-b2b, 
.service_en .difians-b2b, 
.service_en .mougo-b2b, 
.service_en .dantaro-b2b {
    width: 100%;
}
.service_en .service-end, 
.service_en .bonego-b2b, 
.service_en .difians-b2b, 
.service_en .mougo-b2b {
    width: 50%;
}
}



@media screen and (max-width: 1000px) {
    .servicemainSlider .tbox {
        margin-top: 30px;
    }
    #container {
        padding: 0px 16px;
    }
    .servicemainSlider h4{
        font-size: 22px;
    }
    .moeugo .btndiv {
        margin-top: 60px;
    }
    .dantaro .div-img img {
        width: 150px;
    }
    .Dfians .div-img img {
        width: 230px;
    }
    .moeugo .btndiv button {
        height: 60px;
    }
    .moeugo .btndiv button a {
        line-height: 60px;
    }
}

@media screen and (max-width: 900px) {
    ul.phone_moeugo img {
        max-width: 240px;
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .moeugo .logo {
        height: 220px;
        background-size: 300px auto;
    }

    .grid-display .flex-div p {
        font-size: 13px;
    }

    .moeugo h4 {
        font-size: 30px;
    }
    .section_title {
        font-size: 28px;
        margin-bottom: 0;
    }
    .section_title_box {
        margin-bottom: 0px;
    }

    .moeugo .section_title_box {
        margin-bottom: 30px;
    }
    .moeugo .btndiv button {
        font-size: 16px;
    }
    .phone {
        width: 100%;
        overflow: hidden;
    }

    ul.phone_moeugo {
        max-width: 1060px;
        margin: auto;
        text-align: center;
    }

    .dantaro-t {
        font-size: 30px;
        padding-top: 10px;
    }
    .dantaro-colort {
        font-size: 14px;
    }

    .dantaro-b2b p,
    .mougo-b2b p,
    .difians-b2b p,
    .bonego-b2b p,
    .service-end p  {
        font-size: 14px;
    }

    .service-end,
    .bonego-b2b,
    .difians-b2b,
    .mougo-b2b,
    .dantaro-b2b {
        width: 110px;
    }

    .grid-display {
        display: block;
    }

    .grid-display .flex-div p {
        font-size: 13px;
    }

    .grid-display .img_wrap > img {
        width: 100%;
        max-width:340px;
    }

    .grid-under {
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }

    .flex-underbox {
        font-size: 14px;
    }
}

@media screen and (max-width: 460px) {
    .moeugo .btndiv button {
        font-size: 14px;
    }
    .moeugo .logo {
        height: 220px;
        background-size: 200px auto;
    }
    ul.phone_moeugo img {
        max-width: 340px;
        height: auto;
    }
    .grid-display .flex-div p {
        font-size: 14px;
    }
}

@media screen and (max-width: 290px){
    .moeugo .btndiv button a{
        line-height: 1.4;
        padding-top: 15px;
    }
}